<template>
  <div class="text-sm text-center font-medium text-bb-text-default">
    <div class="flex flex-wrap justify-center space-x-2">
      <div
        class="flex items-center"
        :class="{ 'gap-1': unit !== '%' }"
      >
        <p class="pNumLarge">{{ valueA }}</p>
        {{ unit }}
      </div>
      <div>vs</div>
      <div
        class="flex items-center"
        :class="{ 'gap-1': unit !== '%' }"
      >
        <p class="pNumLarge">{{ valueB }}</p>
        {{ unit }}
      </div>
    </div>
    <div class="flex flex-wrap gap-1 items-center justify-center">
      <IcArrow
        :direction="delta > 0 ? 'up' : 'down'"
        size="16"
        :class="textColor"
      />
      <div
        class="flex items-center"
        :class="{ 'gap-1': unit !== '%' }"
      >
        <p class="pNumLarge">{{ delta }}</p>
        {{ unit }}
      </div>
    </div>
  </div>
</template>

<script>
import IcArrow from '@/components/icon/ic-arrow.vue'
export default {
  name: 'DeltaArrow',
  components: { IcArrow },
  props: {
    valueA: {
      type: [String, Number],
      required: true,
    },
    valueB: {
      type: [String, Number],
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    reversed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    versus() {
      return `${this.valueA}${this.unit} vs  ${this.valueB}${this.unit}`
    },
    delta() {
      const valueA = parseFloat(this.valueA)
      const valueB = parseFloat(this.valueB)

      if (valueA === 0 || valueB === 0) return '-'

      const percentageChange = ((valueA - valueB) / valueB) * 100
      return parseFloat(percentageChange.toFixed(2))
    },
    textColor() {
      if (this.reversed) {
        return this.delta > 0 ? 'text-bb-error' : 'text-success'
      }
      return this.delta > 0 ? 'text-success' : 'text-bb-error'
    },
  },
}
</script>

<style scoped lang="scss"></style>
