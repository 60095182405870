<template>
  <div class="banner rounded-2xl bg-purple-0">
    <div class="flex gap-x-6 justify-center p-6">
      <img
        class="w-10 h-32 w-32"
        src="/content/images/brightbid/robot-normal-emotion-key.svg"
      />
      <div>
        <div class="h5">Get keyword suggestions instantly</div>
        <div class="p1">No suggestions available yet. Click the button to create them.</div>
        <merge-button-round
          type="primary"
          class="mt-4"
          :disabled="disabled"
          @click="$emit('submit')"
        >
          Generate Suggestions
          <template #left-icon>
            <ic-refresh
              :size="16"
              class="my-auto"
            />
          </template>
        </merge-button-round>
      </div>
    </div>
  </div>
</template>

<script>
import IcRefresh from '@/components/icon/brightbid/ic-refresh.vue'

export default {
  name: 'NoKwsBanner',
  components: { IcRefresh },
  props: {
    context: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped lang="scss"></style>
