<template>
  <div class="banner rounded-2xl bg-purple-0">
    <div class="flex gap-x-6 justify-center p-6">
      <img
        class="w-10 h-32 w-32"
        src="/content/images/brightbid/robot-normal-emotion-key.svg"
      />
      <div class="w-96">
        <div class="h5">Turn on keyword suggestions for your campaigns</div>
        <div class="p1">
          Activate suggestions for your campaigns to receive smart and meaningful keyword recommendations.
        </div>
        <merge-button-round
          type="primary"
          class="mt-4"
          @click="$emit('submit')"
        >
          Go to Settings
          <template #left-icon>
            <ic-cog
              :size="16"
              class="my-auto"
            />
          </template>
        </merge-button-round>
      </div>
    </div>
  </div>
</template>

<script>
import IcCog from 'vue-material-design-icons/Cog'

export default {
  name: 'NoCampaignsBanner',
  components: { IcCog },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
}
</script>
